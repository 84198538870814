/* General Header Styles */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Shadow to create depth */
  border-bottom-left-radius: 20px; /* Rounded bottom-left corner */
  border-bottom-right-radius: 20px; /* Rounded bottom-right corner */
  color: #011f5f;
  font-family: 'Comfortaa', sans-serif; /* Changed to Comfortaa */
  position: fixed; /* Keep header fixed at the top */
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, padding 0.3s ease, box-shadow 0.3s ease;
  padding: 10px 20px; /* Adjust padding if necessary */
  box-sizing: border-box; /* Ensure padding and border are included in width/height */
}

/* Header styles when scrolled */
header.scrolled {
  background: linear-gradient(135deg, #e0e7ff, #f8f9fa); 
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Add shadow on scroll */
  border-bottom-left-radius: 20px; /* Rounded corners */
  border-bottom-right-radius: 20px;
}

/* Logo styles */
header .logo img {
  width: 250px; /* Default size for larger screens */
  height: auto;
  cursor: pointer;
}

/* Navigation styles */
header nav {
  display: flex;
  align-items: center;
}

header nav ul {
  list-style: none;
  display: flex;
  gap: 20px; /* Adjust spacing between links */
  margin: 0;
  padding: 0;
}

header nav ul li {
  margin: 0;
}

header nav ul li a {
  text-decoration: none;
  color: #011f5f; /* Dark blue color before scroll */
  font-weight: bold;
  padding: 7px 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 5px; /* Slight rounding for links */
  font-family: 'Comfortaa', sans-serif; /* Changed to Comfortaa */
}

/* Link styles after scroll */
header.scrolled nav ul li a {
  color: #011f5f; /* Dark blue color after scroll */
}

header nav ul li a:hover {
  background-color: #FFD700; /* Hover effect remains gold */
  color: #011f5f; /* Hover text color */
}

/* Active link styles */
header nav ul li a.active {
  border-bottom: 3px solid #FFD700;
  background-color: #FFD700;
  color: #042260;
  padding: 10px 20px; /* Added extra padding to left and right for active link */
}

/* Hamburger menu styles for mobile */
.hamburger {
  display: none; /* Hidden on larger screens */
  flex-direction: column;
  cursor: pointer;
  z-index: 10;
  background-color: #042260; /* Ensure visibility with contrasting color */
  padding: 5px; /* Add padding for better click area */
  border-radius: 5px; /* Slight rounding for the button */
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #FFD700; /* Ensure color contrasts with background */
  margin: 4px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hamburger {
    display: flex; /* Show hamburger on smaller screens */
  }

  header nav {
    position: absolute;
    top: 100%; /* Position below header */
    left: 0;
    right: 0;
    background-color: rgb(240, 240, 240); /* White background for mobile menu */
    display: none; /* Hidden by default */
    flex-direction: column;
    align-items: center;
    z-index: 999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for the mobile menu */
  }

  header.nav-open nav {
    display: flex; /* Show menu when open */
  }

  header nav ul {
    flex-direction: column; /* Stack items */
    width: 100%;
    text-align: center;
  }

  header nav ul li {
    margin: 3px 0; /* Adjust spacing for mobile */
  }

  header nav ul li a {
    color: #042260; /* Dark blue text for links on mobile */
    width: 100%;
    padding: 10px 0;
  }

  header nav ul li a.active {
    border-bottom: 2px solid #FFD700;
    background-color: #FFD700;
    color: #042260;
    padding: 15px 20px; /* Increase padding for better touch area on mobile */
  }

  /* Larger logo on smaller screens */
  header .logo img {
    width: 70%; /* Increase logo size on smaller screens */
  }
}
