/* BookingsList.css */
.bookings-list {
    padding: 0.5% 20px;
    font-family: 'Comfortaa', sans-serif;
    text-align: center;
    background: linear-gradient(rgba(250, 250, 250, 0.8), rgba(255, 255, 255, 0.8)),
                url('/public/assets/images/sample.png'); /* Add semi-transparent overlay over the image */
    background-size: cover; /* Make sure the image covers the entire page */
    background-attachment: fixed; /* Make the background fixed */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
}

.bookings-list h2 {
    color: #011f5f; /* Blue color for headings */
    margin-bottom: 20px;
}

.search-input {
    width: 80%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 12px; /* Fancy border radius */
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for better look */
}

table {
    width: 100%; /* Full width on all screens */
    max-width: 1200px; /* Max width for larger screens */
    border-collapse: collapse;
    margin: 0 auto 20px auto; /* Center the table */
    border-radius: 12px; /* Fancy border radius for the table */
    overflow: hidden; /* Ensures rounded corners for the table */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for the table */
}

table th, table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

table th {
    background-color: #9D2235; /* Red color for table headers */
    color: #FFD700; /* Gold color for table header text */
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ddd;
}

.pagination-controls {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pagination-buttons {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between buttons and select */
}

.pagination-button {
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 12px; /* Fancy border radius for buttons */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for buttons */
}

.pagination-button.active {
    background-color: #9D2235; /* Red for active page */
    color: #FFD700; /* Gold for active page text */
}

.pagination-button.disabled {
    background-color: #ddd;
    cursor: not-allowed;
    color: #666;
}

.pagination-button img {
    width: 20px;
    height: 20px;
}

.select-page {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 12px; /* Fancy border radius for select */
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for select */
}

.row-selector {
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 8px;
    border-radius: 12px; /* Fancy border radius for row selector */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for row selector */
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    table {
        display: none; /* Hide the table on small screens */
    }

    .booking-card {
        display: block;
        width: 90%; /* 90% of the screen width */
        margin: 0 auto 10px auto; /* Center the card horizontally */
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 12px; /* Fancy border radius for cards */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for cards */
        background: linear-gradient(to bottom right, #ffffff, #f9f9f9); /* Gradient background for cards */
        transition: background 0.3s ease; /* Smooth transition for background color */
        position: relative; /* Position relative to allow absolute positioning of serial number */
        text-align: left; /* Left align text inside the card */
    }

    .booking-card:hover {
        background: linear-gradient(to bottom right, #f2f2f2, #e0e0e0); /* Gradient background on hover */
    }

    .booking-card div {
        margin-bottom: 8px;
        font-size: 14px;
        display: flex; /* Use flexbox to align items */
        align-items: center; /* Align items vertically centered */
        gap: 4px; /* Space between label and content */
    }

    .booking-card div::before {
        content: attr(data-label); /* Use data-label for the header text */
        font-weight: bold;
        color: #9D2235; /* Red color for labels */
    }

    .serial-number {
        position: absolute;
        bottom: 10px; /* Position at the bottom of the card */
        left: 50%; /* Center horizontally */
        transform: translateX(-50%); /* Adjust for centering */
        font-weight: bold;
        color: #ffffff; /* White color for serial number */
        font-size: 16px; /* Adjust size as needed */
        background-color: #9D2235; /* Dark blue background */
        border-radius: 50%;
        padding: 1.5%;
        display: flex;
        align-items: center;
        justify-content: center; /* Center text inside the background circle */
    }

    /* Background gradient for the overall page */
    .bookings-list {
        background: linear-gradient(rgba(250, 250, 250, 0.8), rgba(255, 255, 255, 0.8)), 
                    url('/public/assets/images/sample.png'); /* Semi-transparent overlay over the image */
        background-size: cover; /* Make sure the image covers the entire page */
        background-attachment: fixed; /* Make the background fixed */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Prevent the image from repeating */
    }
}

@media (min-width: 769px) {
    .booking-card {
        display: none; /* Hide the booking cards on larger screens */
    }
}
