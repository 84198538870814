.booking {
  padding: 0.5%;
  text-align: center;
  font-family: 'Comfortaa', sans-serif;
  background: linear-gradient(rgba(250, 250, 250, 0.8), rgba(255, 255, 255, 0.8)), 
              url('/public/assets/images/sample.png'); /* Add semi-transparent overlay over the image */
  background-size: cover; /* Make sure the image covers the entire page */
  background-attachment: fixed; /* Make the background fixed */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

.booking h2 {
  color: #9D2235; /* Theme color */
  margin-bottom: 0.5%;
}

.booking form {
  max-width: 500px;
  margin: 0 auto;
}

.form-background {
  background-color: #f9f9f9; /* Light background color for the form */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.booking label {
  display: block;
  margin-bottom: 15px;
  text-align: left;
  text-align: center;
}

.booking input[type="text"],
.booking input[type="email"],
.booking input[type="tel"],
.booking input[type="date"],
.booking select,
.booking textarea {
  width: calc(100% - 20px); /* Uniform width with padding and margin considered */
  padding: 10px;
  margin: 5px 10px; /* 10px on left and right to ensure consistent spacing */
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box; /* Ensure padding is included in width */
}

.booking input[type="text"].error-input,
.booking input[type="email"].error-input,
.booking input[type="tel"].error-input,
.booking input[type="date"].error-input,
.booking select.error-input,
.booking textarea.error-input {
  border: 1px solid #ff0000;
  background-color: #ffcccc;
}

.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-top: 5px;
}

.custom-dropdown {
  position: relative;
}

.dropdown-header {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-icon {
  margin-left: 10px;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.booking button {
  background-color: #9D2235;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: calc(100% - 20px);
}

.booking button:hover {
  background-color: #003366;
}

.booking .error {
  color: #ff0000;
}
 
.toast-container {
  font-family: Arial, sans-serif;
  font-size: 14px;
}