.services {
  padding: 0.5%;
  text-align: center;
  font-family: 'Comfortaa', sans-serif;
  background: linear-gradient(rgba(250, 250, 250, 0.8), rgba(255, 255, 255, 0.8)), 
              url('/public/assets/images/sample.png'); /* Add semi-transparent overlay over the image */
  background-size: cover; /* Make sure the image covers the entire page */
  background-attachment: fixed; /* Make the background fixed */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

.services h1 {
  color: #9D2235; /* Theme color */
  margin-bottom: 0.5%;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-card {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 12px 30px 12px 30px; /* Creative border radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to right, #FFD700 2%, #042260 3%);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  position: relative;
  color: #ffffff;
  overflow: hidden;
}

.service-card:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}

.service-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  transition: opacity 0.3s;
}

.service-card .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.service-card .content.show {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #FFD700 2%, #042260 3%);
  color: white;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  opacity: 1;
}

.service-card .content h3 {
  margin: 10px 0;
  color: #FFD700; /* Theme color */
}

.service-card .content p {
  margin: 0;
}

.service-card img.hidden {
  opacity: 0; /* Hide image on hover */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Add overlay background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #042260;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
}

.popup-content img {
  width: 100%; /* Make the image full width */
  height: auto;
  border-radius: 10px 10px 0 0;
}

.popup-content hr {
  border: 1px solid #FFD700; /* Theme color */
  margin: 20px 0;
}

.popup-content h2 {
  color: #FFD700; /* Theme color */
}

.popup-content p {
  color: #ffffff;
}

.close-btn {
  position: absolute;
  top: 20px;
  color:#ff0228;
  right: 20px;
  font-size: 200%;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.overlay-active {
  overflow: hidden;
}
