.about-us {
  padding: 0.5% 20px;
  font-family: 'Comfortaa', sans-serif;
  text-align: center;
  background-color: #f8f8f8;
  background: linear-gradient(rgba(250, 250, 250, 0.8), rgba(255, 255, 255, 0.8)), 
              url('/public/assets/images/sample.png'); /* Add semi-transparent overlay over the image */
  background-size: cover; /* Make sure the image covers the entire page */
  background-attachment: fixed; /* Make the background fixed */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

.about-us h1 {
  color: #9D2235;
  margin-bottom: 0.5%;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 70%;
  margin: auto;
}

.about-content .card {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, background 0.3s ease;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 12px 30px 12px 30px; /* Creative border radius */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #e0e7ff, #f8f9fa); /* Background gradient before hover */
  background-color: #fff;
  color: #000000;
}

.about-content .card h2 {
  color: #9D2235;
  margin-bottom: 10px;
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.about-content .card p {
  color: #011f5f; /* Theme blue color for initial text */
  transition: color 0.3s ease; /* Transition for color change */
}

/* Apply hover styles to the text elements inside the cards, excluding headings */
.about-content .card:hover p {
  color: white; /* Change text color to white on hover */
}

/* Change h2 color to gold/yellow on hover */
.about-content .card:hover h2 {
  color: #FFD700; /* Theme yellow/gold color */
}

/* Apply the gradient background on card hover */
.about-content .card:hover {
  background: linear-gradient(to right, #FFD700 0.5%, #042260 0.7%);
}

.about-content .card.fade-in {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .about-us {
    padding: 20px;
  }

  .about-content .card {
    padding: 15px;
  }
}
