/* ContactUs.css */
.contact-us {
  padding-top: 0.5%;
    background-color: #fff;
    text-align: center;
    font-family: 'Comfortaa', sans-serif;
  background: linear-gradient(rgba(250, 250, 250, 0.8), rgba(255, 255, 255, 0.8)), 
              url('/public/assets/images/sample.png'); /* Add semi-transparent overlay over the image */
  background-size: cover; /* Make sure the image covers the entire page */
  background-attachment: fixed; /* Make the background fixed */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  }
  
  .contact-us form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .contact-us form label {
    margin: 10px 0 5px;
    color: #9D2235;
  }
  
  .contact-us form input,
  .contact-us form textarea {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
  }
  
  .contact-us form button {
    padding: 10px 20px;
    background-color: #9D2235;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-us .contact-info {
    margin-top: 20px;
  }
  
  .contact-us .contact-info p {
    margin: 5px 0;
  }
  .contact-us .h2 {
    color: #9D2235; /* Theme color */
    margin-bottom: 0.5%;
  }
  
