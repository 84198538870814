/* Footer styling */
footer {
  background-color: #042260; /* Dark blue background */
  color: white;
  padding: 20px;
  text-align: center;
  font-family: 'Comfortaa', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer .footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Adjust based on your layout */
}

footer .footer-content p {
  margin: 5px 0;
}

footer address p {
  margin: 10px 0;
}

footer address .icon-text {
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
}

footer address .icon-text svg {
  margin-right: 8px;
  color: #FFD700; /* Gold color for icons */
}

footer .social-links {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

footer .social-links a {
  text-decoration: none;
  color: #FFD700; /* Gold color for icons */
  margin: 0 10px;
  font-size: 24px;
  transition: color 0.3s ease;
}

footer .social-links a:hover {
  color: #ffa202; /* Gold color on hover */
}

/* Map container inside the footer */
.footer-map {
  width: 100%;
  max-width: 1000px;
  height: 300px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visual */
}

.footer-map iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  footer .footer-content {
    padding: 10px;
  }

  footer .social-links a {
    font-size: 20px;
    margin: 0 8px;
  }

  .footer-map {
    height: 200px;
  }
}
