.home {
  font-family: 'Comfortaa', sans-serif;
  background: linear-gradient(rgba(250, 250, 250, 0.7), rgba(255, 255, 255, 0.9)), 
              url('/public/assets/images/sample.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.hero {
  position: relative;
  text-align: center;
  color: white;
  margin: 0;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-color: black;
  opacity: 1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  animation: fadeIn 2s ease-in-out;
}

.hero-text h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.home-logo {
  width: 250px;
  height: auto;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.primary-btn {
  background-color: #9D2235;
  color: white;
}

.primary-btn:hover {
  background-color: #7a1a29;
}

.secondary-btn {
  background-color: #042260;
  color: white;
}

.secondary-btn:hover {
  background-color: #002244;
}

.intro,
.services-overview,
.about-overview,
.testimonials {
  padding: 60px 20px;
  text-align: center;
}

.intro h2,
.services-overview h2,
.about-overview h2,
.testimonials h2 {
  color: #9D2235;
  margin-bottom: 40px;
}

.intro-content,
.service-items,
.about-items,
.testimonial-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.intro-text {
  flex: 1;
  min-width: 300px;
  text-align: left;
  animation: slideInLeft 1s ease-in-out;
  padding: 1%;
  border-radius: 12px 30px 12px 30px; /* Creative border radius */
  color: #042260;
  background: linear-gradient(135deg, rgba(224, 231, 255, 0.4), rgba(248, 249, 250, 0.4)); /* Opacity added */
}


.intro-image {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  animation: fadeInUp 1s ease-in-out;
}

/* Enhanced card styles */
.service-item,
.about-item,
.testimonial-item {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 12px 30px 12px 30px; /* Creative border radius */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  color: #042260;
  background: linear-gradient(135deg, #e0e7ff, #f8f9fa); /* Background gradient before hover */
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.3s, background 0.3s, border-radius 0.3s;
  
}

/* Hover effects without translation */
.service-item:hover,
.about-item:hover,
.testimonial-item:hover {
  background: linear-gradient(to right, #FFD700 2%, #042260 3%);
  color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow effect */
  border-radius: 30px 12px 30px 12px; /* Reverse border radius on hover */
}

.service-item a,
.about-item a,
.testimonial-item a {
  text-decoration: none;
  color: inherit;
}

/* Card title color on hover */
.service-item:hover h3,
.about-item:hover h3,
.testimonial-item:hover h3 {
  color: #fff;
}

.view-more {
  margin-top: 20px;
}

.reveal {
  opacity: 1;
  transform: translateY(0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .intro-content {
    flex-direction: column;
    text-align: center;
  }

  .intro-text {
    text-align: center;
  }

  .intro-image {
    margin-top: 20px;
  }

  .service-item,
  .about-item,
  .testimonial-item {
    min-width: 100%;
  }
}
